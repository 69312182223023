const config = {
  kumhoTyreRange: [
    {
      id: "1",
      name: "ECSTA",
      image: "ecsta/ecsta-thumb.webp",
      tyre_image: "ecsta/ecsta-tyre.webp",
      typeCount: 2,
      tyreType: [
        {
          id: "1",
          name: "ECSTA-PS71",
          title: "ECSTA PS71",
          subTitle:
            "A sports performance tyre designed for driving refinement",
          slider: [
            {
              image:
                "ecsta/ecsta-ps71/tyres/ecsta_ps71-1.webp",
              alt: "ecsta PS71",
            },
            {
              image:
                "ecsta/ecsta-ps71/tyres/ecsta_ps71-2.webp",
              alt: "ecsta PS71",
            },
            {
              image:
                "ecsta/ecsta-ps71/tyres/ecsta_ps71-3.webp",
              alt: "ecsta PS71",
            },
          ],
          product_portfolo: [
            "85 Sizes",
            "16 - 21 Inches",
            "Series 30 - 65",
            "Speed index: Y / V / W",
          ],
          product_portfolo_image:
            "ecsta/ps71-background-portfolio.webp",
          video:
            "https://www.youtube.com/embed/Gd2JGZFrcxY?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Gd2JGZFrcxY",
          features: [
            "Design to deliver fine and stable driving with superb handling and braking performance on wet and dry surfaces",
            "<b>Compound technology</b>",
            [
              "<b>High Dispersion, Micro Silica:</b>",
              "Maximizes wear, handling performance and fuel efficiency by application of high-dispersion silica"
            ],
            [
              "<b>Maximized driving performance:</b>",
              "4 main wide grooves direct water drainage out of tread area performance resin"
            ],
            [
              "<b>Increased durability:</b>",
              "Application of new hybrid cap ply made of steel belts strengthens casing",
              "Inclusion of a fabric insert into the sidewall provides extra rigidity"
            ]
          ],
          brochure:
            "static/pdf/brochures/ecsta_ps71_brochure.pdf",
        },
        {
          id: "2",
          name: "ECSTA-HS52",
          title: "ECSTA HS52",
          description: "Award winning new ECSTA HS52 pattern has been designed to provide the perfect balance between comfort and performance, offering drivers of premium sedans and sports vehicles a comfortable ride along with high levels of safety through high grip and braking performance",
          slider: [
            {
              image:
                "ecsta/ecsta-hs52/tyres/ecsta_hs52-1.webp",
              alt: "WILDPEAK A/T TRAIL NEW",
            },
            {
              image:
                "ecsta/ecsta-hs52/tyres/ecsta_hs52-2.webp",
              alt: "WILDPEAK A/T TRAIL NEW",
            },
            {
              image:
                "ecsta/ecsta-hs52/tyres/ecsta_hs52-3.webp",
              alt: "WILDPEAK A/T TRAIL NEW",
            },
          ],
          product_portfolo: [
            "2 Sizes",
            "15 - 17 Inches",
            "Series 55",
            "Speed index: V / W",
          ],
          product_portfolo_image:
            "ecsta/hs52-background-portfolio.webp",
          video:
            "https://www.youtube.com/embed/l39d6B548JE?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=l39d6B548JE",
          features: [
            [
              "<b>Asymmetrical Tread Design:</b>",
              "Asymmetric tread design provides the highly balanced handling performance on both wet and dry surfaces. Wide tread grooves reduce give high levels of aquaplaning resistance through optimizes water drainage"
            ],
            [
              "<b>Multi Pitch:</b>",
              "Optimal pitch arrangement of tread blocks gives increased levels of comfort whilst lowering sound emissions"
            ],
            [
              "<b>Compound Technology:</b>",
              "A unique blend of high grip resins combined with highly dispersible silica and multi-functionalised polymers combine to provide high levels of wet and dry performance along with increased mileage over its predecessor"
            ],
            [
              "<b>Award Winning Design:</b>",
              "Winner of Prestigious IF Design Award 2022"
            ]
          ],
          brochure:
            "static/pdf/brochures/ecsta_hs52_brochure.pdf",
        }
      ],
    },
    {
      id: "2",
      name: "SOLUS",
      image: "solus/solus-thumb.webp",
      tyre_image: "solus/solus-tyre.webp",
      typeCount: 1,
      tyreType: [
        {
          id: "1",
          name: "SOLUS-TA21",
          title: "SOLUS TA21",
          description:
            "The TA21 is developed from 60 years of Kumho technology to provide a mid-level touring tyre which provides drivers with a balanced performance with a quiet and comfortable ride.",
          typeCount: 0,
          tyreType: [{}],
          video:
            "https://www.youtube.com/embed/O59jpSZJiFc?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=O59jpSZJiFc",
          features: [
            "Improved ride comfort and noise reduction technology through the application of increased tread pitches and optimised bead design",
            "Application of multi-functional silica compound combined with optimised tread pattern with 3D interlocking sipes give the TA21 a balanced performance across all season",
            "Application of micro silica compound, 3D Chamber tread blocks and optimised carcass design all combine to give improved tyre life and longer mileage",
          ],
          slider: [
            {
              image:
                "solus/solus-ta21/tyres/solus_ta21-1.webp",
              alt: "Solus TA21",
            },
            {
              image:
                "solus/solus-ta21/tyres/solus_ta21-2.webp",
              alt: "Solus TA21",
            },
            {
              image:
                "solus/solus-ta21/tyres/solus_ta21-3.webp",
              alt: "Solus TA21",
            },
          ],
          brochure:
            "static/pdf/brochures/solus_ta21_brochure.pdf",
          product_portfolo: [
            "2 Sizes",
            "13 - 18 Inches",
            "Series 45 - 80",
            "Speed index: H / T / S / W",
          ],
          product_portfolo_image:
            "solus/solus-image.webp",
        }
      ],
    },
    {
      id: "3",
      name: "ECOWING",
      image: "ecowing/ecowing-thumb.webp",
      tyre_image: "ecowing/ecowing-tyre.webp",
      typeCount: 1,
      tyreType: [
        {
          id: "1",
          name: "ECOWING-ES31",
          title: "ECOWING ES31",
          subTitle: "The Kumho Ecowing ES31 is a car tyre developed to help reduce the fuel consumption of a car",
          description:
            "Kumho have designed the Ecowing ES31 with an eco-friendly philosophy - using eco-compounds in the tyre's makeup." +
            " " +
            "The tyre features a asymmetric tread pattern, to enhance handling performance in both wet and dry conditions." +
            " " +
            "An optimal wet performance is achieved through the use of specially designed 4 wide main grooves within the tread, to quickly and efficiently remove surface water from the path of the tyre - also helping in the reduced risk of aquaplaning." +
            " " +
            "Kumho have also incorporated an aerodynamic shoulder design, for improved air resistance - in turn helping to enhance the tyre's fuel efficient capabilities.",
          slider: [
            {
              image:
                "ecowing/ecowing-es31/tyres/ecowing_es31-1.webp",
              alt: "ECOWING ES31",
            },
            {
              image:
                "ecowing/ecowing-es31/tyres/ecowing_es31-2.webp",
              alt: "ECOWING ES31",
            },
            {
              image:
                "ecowing/ecowing-es31/tyres/ecowing_es31-3.webp",
              alt: "ECOWING ES31",
            },
          ],
          product_portfolo: [
            "22 Sizes",
            "13 - 17 Inches",
            "Series 45 - 70",
            "Speed index: H / T / W / V",
          ],
          product_portfolo_image:
            "ecowing/ecowing-es31-background-portfolio.webp",
          video:
            "https://www.youtube.com/embed/qdsh9Mjh4AY?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=qdsh9Mjh4AY",
          features: [
            "Developed to reduce fuel consumption and preserve the environment by using eco-compounds",
            "Secure handling & traction performance",
            "Maximum comfort and quiet",
            "Improved endurance and mileage",
            "For compact, medium and upper medium cars",
          ],
          brochure:
            "static/pdf/brochures/ecowing_es31_brochure.pdf",
        }
      ],
    },
    {
      id: "4",
      name: "CRUGEN",
      image: "crugen/crugen-thumb.webp",
      tyre_image: "crugen/crugen-tyre.webp",
      typeCount: 1,
      tyreType: [
        {
          id: "1",
          name: "CRUGEN-HP71",
          title: "CRUGEN HP71",
          description:
            "The Crugen HP71 grants a top-tier luxurious feel and quiet ride. However, it is the level of safety, performance, and longevity that makes the Crugen HP71 an ideal match for today's CUVs and SUVs. With Kumho's state of the art technology, no matter the season or the weather you will feel confident on the road.",
          video:
            "https://www.youtube.com/embed/eT1oWnQ3j-o?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=eT1oWnQ3j-o",
          features: [
            "<b>Wet performance:</b> Wide shoulder and circumferential tread grooves combined with diagonal sipe micro grooves help evacuate water and improve hydroplaning resistance",
            "<b>Handling stability:</b> New 3D Sipe structure technology and optimised bead profile provide a greater tread contact area to improve driving stability and performance",
            "<b>Long mileage:</b> Application of micro silica and high grip resin compound and optimised carcass design all combine to give improved tyre life and long mileage",
            "<b>Luxurious appearance:</b> Hologram sidewall technology has been applied to the sidewall gives a luxurious and refined appearance",
          ],
          slider: [
            {
              image:
                "crugen/crugen-hp71/tyres/crugen_hp71-1.webp",
              alt: "CRUGEN HP71",
            },
            {
              image:
                "crugen/crugen-hp71/tyres/crugen_hp71-2.webp",
              alt: "CRUGEN HP71",
            },
            {
              image:
                "crugen/crugen-hp71/tyres/crugen_hp71-3.webp",
              alt: "CRUGEN HP71",
            },
          ],
          brochure:
            "static/pdf/brochures/crugen_hp71_brochure.pdf",
          product_portfolo: [
            "18 Sizes",
            "16 - 21 Inches",
            "Series 45 - 70",
            "Speed index: H / V / W",
          ],
          product_portfolo_image:
            "crugen/crugen-hp71-background-portfolio.webp",
        }
      ],
    },
    {
      id: "5",
      name: "ROADVENTURE",
      image: "roadventure/roadventure-thumb.webp",
      tyre_image: "roadventure/roadventure-tyre.webp",
      typeCount: 3,
      tyreType: [
        {
          id: "1",
          name: "ROAD-VENTURE-AT52",
          title: "ROAD VENTURE AT52",
          description:
            "The new AT52 blends the perfect combination of performance to take command of rugged terrains whilst also delivering on-road comfort and providing year-round all-season traction.",
          video:
            "https://www.youtube.com/embed/8W92-1BlA80?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=8W92-1BlA80",
          features: [
            "<b>Enhanced traction tread design:</b> Deep lateral and wide sub-lateral grooves combined with high-density siping help provide the perfect combination of clawing action off road, whilst also improving wet performance both on and off-road",
            "<b>Extreme side-biters:</b> Rugged and aggressive side-biters are a 100% increase in 3D biter depth compared to its predecessor and increased shoulder traction whilst adding additional puncture protection",
            "<b>Wear resistant compound:</b> Featuring a complex carbon black compound with an optimal polymer blend ratio helps the pattern resist irregular wear and improves cut-and-chip resistance with an overall improvement of tyre life by up to 20% compared to its predecessor",
          ],
          slider: [
            {
              image:
                "roadventure/roadventure-at52/tyres/roadventure_at52-1.webp",
              alt: "ROAD VENTURE AT52",
            },
            {
              image:
                "roadventure/roadventure-at52/tyres/roadventure_at52-2.webp",
              alt: "ROAD VENTURE AT52",
            },
            {
              image:
                "roadventure/roadventure-at52/tyres/roadventure_at52-3.webp",
              alt: "ROAD VENTURE AT52",
            },
          ],
          brochure:
            "static/pdf/brochures/roadventure_at52_brochure.pdf",
          product_portfolo: [
            "13 Sizes",
            "15 - 19 Inches",
            "Series 55 - 75",
            "Speed index: T / R",
          ],
          product_portfolo_image:
            "roadventure/roadventure-at52-background-portfolio.webp",
        },
        {
          id: "2",
          name: "ROAD-VENTURE-MT51",
          title: "ROAD VENTURE MT51",
          description:
            "Aggressive off road design balancing on-off road (50%+50%) performance regular new MT",
          video:
            "https://www.youtube.com/embed/QxDhcqWINjg?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=QxDhcqWINjg",
          features: [
            "Mild MT for mine and mountain climbing",
            "Improved on-road driving performance",
            "Stone ejector and mud cleaning performance",
            "<b>On-off road balancing performance improvement:</b> Aggressive block & dual pitch applied for mud, rock crawling & on-road performance",
            "<b>Durability improvement:</b> Prevention of crack, cut & chip, stone drilling",
            "<b>Traction performance improvement:</b> 3 level height block improves its stiffness",
            "Zigzag groove, notching shoulder reduces damage, off road traction up",
            "<b>Mud & stone emission:</b> Stone reject bar & under groove decoration improves stones & mud rejection",
          ],
          slider: [
            {
              image:
                "roadventure/roadventure-mt51/tyres/roadventure_mt51-1.webp",
              alt: "ROAD VENTURE MT51",
            },
            {
              image:
                "roadventure/roadventure-mt51/tyres/roadventure_mt51-2.webp",
              alt: "ROAD VENTURE MT51",
            },
            {
              image:
                "roadventure/roadventure-mt51/tyres/roadventure_mt51-3.webp",
              alt: "ROAD VENTURE MT51",
            },
          ],
          brochure:
            "static/pdf/brochures/roadventure_mt51_brochure.pdf",
          product_portfolo: [
            "5 Sizes",
            "16 - 17 Inches",
            "Series 70 - 85",
            "Speed index: Q",
          ],
          product_portfolo_image:
            "roadventure/roadventure-mt51-background-portfolio.webp",
        },
        {
          id: "4",
          name: "ROAD-VENTURE-MT71",
          title: "ROAD VENTURE MT71",
          description:
            "If you are looking for a tyre that will get you through nature's toughest obstacles, look no further than the Road Venture MT71! With our high void tread pattern, you are guaranteed maximum off-road traction! We've also added in stone ejector bars to prevent damage from stone drills which aids self-cleaning." +
            " " +
            "With the Road Venture MT71's aggressive tread design, you will have better off-road traction, durability, and on-road handling. Dual pitch designed with zig-zag center block offers maximum off-road traction Improved vertical & lateral block rigidity offers better handling Chamfer cut : Angled c-cut design applied for maximum traction.",
          video:
            "https://www.youtube.com/embed/2TnbRAP5H7k?controls=0&rel=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=2TnbRAP5H7k",
          features: [
            "Aggressive design for increased climbing performance and off-road traction",
            "Enhanced durability with cut-and-chip resistance",
            "Exceptional off-road performance with excellent highway handling",
            "Off road Traction - High-void tread pattern features a zig-zag and dual-pitch design to provide maximum off-road traction",
            "Handling - Increased block stiffness for enhanced on/off-road handling performance characteristics",
            "Stone Ejector bar - Application of mud and stone ejector bars between the shoulder lugs to prevent damage from stone drills and aid self-cleaning",
            "Compound - Cut-and-Chip resistance and enhanced wear performance",
          ],
          slider: [
            {
              image:
                "roadventure/roadventure-mt71/tyres/roadventure_mt71-1.webp",
              alt: "ROAD VENTURE MT71",
            },
            {
              image:
                "roadventure/roadventure-mt71/tyres/roadventure_mt71-2.webp",
              alt: "ROAD VENTURE MT71",
            },
            {
              image:
                "roadventure/roadventure-mt71/tyres/roadventure_mt71-3.webp",
              alt: "ROAD VENTURE MT71",
            },
          ],
          brochure:
            "static/pdf/brochures/roadventure_mt71_brochure.pdf",
          product_portfolo: [
            "5 Sizes",
            "15 Inches",
            "Series 75",
            "Speed index: Q",
          ],
          product_portfolo_image:
            "roadventure/roadventure-mt71-background-portfolio.webp",
        }
      ],
    },
  ],
  newsRoomDetails: [
    {
      id: "1",
      title: "KUMHO DECLARES OPENING ROUND OF TCR WORLD TOUR  A SUCCESS",
      bannerImageText:
        "",
      subTitle: "Wednesday, 24 May 2023",
      image:
        "thumbnail-tcr_world_tour.webp",
      description: `
        <p>Global tyre company, Kumho has declared the opening rounds of the Kumho TCR World Tour, and the first races using Kumho as the control tyre a success with close racing and strong data returns for Kumho engineers.</p>
        <p>The opening round of the Kumho TCR World Tour were held at the challenging Algarve International Circuit in Portugal on 29-30 April at the start of the series, that will conclude with the final two rounds in Australia at Sydney Motorsport Park and Mt Panorama at Bathurst on successive weekends in November.</p>
        <p>Kumho Tyre signed on as the exclusive tyre sponsor and official partner of the TCR World Tour in February in a three-year title sponsorship deal with World Sporting Consulting Group (WSC) which manages and runs the TCR World Tour.</p>
        <p>The Kumho TCR World Tour features many of the world's prestigious car manufacturers including Audi, Honda, Hyundai, Kia, Peugeot, Renault, Alfa Romeo, Cupra and new Volvo/Geely owned brand Lynk&Co and is growing stronger with more manufacturers looking to become part of the World Tour.</p>
        <p>The Portugese round provided some captivating racing with the Audi RS 3 LMS, Honda Civic Type R, and Hyundai Elantra N vying for the trophy.</p>
        <p>All cars competing in the Tour use either the Kumho Ecsta S700 slick tyre for dry track conditions or the Kumho Ecsta W701 wet tyre in rainy conditions.</p>
        <p>The opening races were held in conjunction with the TCR Europe series, which Kumho has been the official tyre supplier for since last year.</p>
        <p>The challenging Algarve International Circuit was the perfect backdrop to the opening round of the Kumho TCR World Tour with its rollercoaster undulations and acute-angled corners.</p>
        <p>Hungarian driver Norbert Michelisz of BRC Hyundai N Squadra Corse team took victory in the opening races and despite relentless challenges he won the opening round and leads the  heading to round two  at the famed Spa-Francochamps circuit in Belgium at the end of May.</p>
        <p>"The challenging setting at the Algarve International Circuit in Portugal gave us an opportunity to showcase the performance of our tyres," said Janghyuk Yoon (John Yoon), senior vice president of Global Marketing for Kumho Tyre.</p>
        <p>"Kumho will continue  to reinforce its motorsport marketing activities with the KUMHO TCR World Tour to further emphasise its credentials as a global high-performance tyre maker," said Janghyuk Yoon.</p>
        <div class="text-center"><div class="big_img mb-3"><img class="img-fluid" src="${getImgUrl('tcr_world_tour-1.webp')}" /></div></div>
        <p>Following the opening rounds in Portugal and Belgium the Tour will move across seven countries on four continents including Belgium, Italy, Hungary, Uruguay, Argentina, Australia, and Macau.</p>
        <p>Kumho started its relationship with TCR sponsoring key national and regional TCR events in Europe in 2020.</p>
        <p>This year Kumho added two additional regional TCR series in South America and the Middle East and three national TCR series in Australia, Brazil, and Finland to its portfolio of TCR championships it sponsor and supplies tyres for.</p>
      `,
    },
    {
      id: "2",
      title:
        "KUMHO TYRE JOINS AC MILAN AS NEW PREMIUM PARTNER",
      bannerImageText: "",
      subTitle: "Milan, July 2023",
      image:
        "thumbnail-ac_milan_premium_partner.webp",
      description: `
        <p>Kumho has announced that it has entered into a partnership with Italian football powerhouse AC Milan that will see the company become the \"Rossoneri's\" new long-term Premium Partner and Official Tyre Partner.</p>
        <p>The collaboration between the two global brands, which have both demonstrated excellence in their respective fields, lays its foundation on the same set of shared values, which drive them to lead the way through the pursuit of change, innovation and competence and an open mind.</p>
        <p>Passion, devotion and a constant search for performance are also fundamental principles for both AC Milan and Kumho Tyre.</p>
        <p>Kumho Tyre plans to strengthen and diversify its marketing and investment to reach a greater customer base in key markets the partnership, thanks to the Club's premium positioning, attention to quality and global appeal, which will also enhance its presence as a premium brand.</p>
        <p>\"We are very pleased to form a premium partnership with AC Milan, a world-renowned football club that boasts a huge international fan base," said CEO of Kumho Tyre, Il-Taik Jung.</p>
        <p>\"We will use this partnership to enhance our brand recognition globally and initiate a range of activities to position ourselves as a premium brand," Jung added.</p>
        <p>CEO of AC Milan Giorgio Furlani said the Club is delighted to welcome Kumho Tyre to its family of Premium Partners and to start a brand-new journey together.</p>
        <p> \"Thanks to our common set of values and our common vision, our partnership will inspire us both to further push boundaries and keep innovating," Furliani said.</p>
        <p>"Starting a journey alongside a partner like Kumho makes us proud and testifies to the global influence and relevance of our brand, with over 500 million fans worldwide," added Casper Stylsvig, AC Milan's Chief Revenue Officer.</p>
        <p>"We are confident that this new path will lead us to achieve together all the goals we have set ourselves," Stylsvig added.</p>
      `,
    },
    {
      id: "3",
      title:
        "VOLKSWAGEN SELECTS KUMHO CRUGEN HP71 FOR NEW ELECTRIC SUV",
      bannerImageText: "",
      subTitle: "Thursday, 13 July 2023",
      image:
        "thumbnail-volkswagen_kumho_crugen.webp",
      description: `
        <p>Volkswagen has announced it has selected Kumho Tyre's Crugen EV HP71 as an original equipment fit for its popular pure electric SUV the ID.4'.</p>
        <p>Volkswagen ID.4 ranked first among European-branded electric vehicles in 2022 across10 European countries, including Germany, thanks to its price competitiveness and popularity in the medium SUV market. Volkswagen is set to launch the ID.4 in Australia in 2024</p>
        <p>Kumho wilI provide four different size specification of the EV HP71 for the ID4 to suit different model variants including in 235/55 R19, 255/50 R19, 235/50 R20, and 255/45 R20.</p>
        <p>Kumho Tyre's CRUGEN EV HP71 is a high-performance premium comfort SUV tyre which has been revised with a spec to suit electric vehicles.</p>
        <p>Its optimal EV compound including its high-dispersion precision silica, was selected to maximise ride and braking performance, and in addition the CRUGEN HP71 EV has been constructed for improved driving stability, significantly better wear performance and stronger braking power compared to other tyres.</p>
        <p>Kumho has also applied new tyre noise reduction technology which distributes noise generated from 'dimple' design tyre grooves, which was developed using a pattern design analysis system.</p>
        <p>"The number and supply of electric vehicles is increasing as automakers aim for carbon neutrality and Kumho Tyre is also striving to supply tyres for cars that can maximise electric car performance," said Kumho Tyre, senior vice president of OE sales, Insoo Kim.</p>
        <p>"We will provide customers with a better mobility experience by collaborating with premium automakers and by producing market leading products through continuous research and development," he said.</p>
        <p>Kumho Tyre is also developing OE tyres for next-generation electric vehicles in collaboration with major global car makers.</p>
        <p>Kumho Tyre has also supplied EV tyres to KIA for its EV6, which has been gaining popularity globally since its launch in August last year.</p>
        <p>These new EV tyres demonstrate Kumho's superior technology and quality.</p>
      `,
    },
  ],
};

function getImgUrl(pic : any) {
  var images = require.context('@/assets/images/kumho/news-page-content/')
  return images('./' + pic)
}

export default config;
