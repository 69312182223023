<section class="the_story p-0">
  <div class="container-fluid p-0">
    <div class="row g-0 flex-md-row-reverse">
      <div class="col-lg-6">
        <div class="img">
          <img
            src="@/assets/images/kumho/kumho-warranty.webp"
            alt="Kumho Warranty"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-end">
        <div class="text">
          <!-- <p class="sub_title">LOREM IPSUM DOLOR</p> -->
          <h2 class="title">Kumho Tyre Achievements</h2>
          <ul>
            <li>Kumho Tyre is one of the top 10 tyre manufacturers in the world.</li>
            <li>The company's tyres are known for their quality, performance, and durability.</li>
          </ul>
          <h2 class="title">Other notable achievements</h2>
          <ul>
            <li>Kumho Tyre has won numerous awards for its tyre designs, including the Red Dot Design Award, the iF Design Award, and the IDEA Design Award.</li>
            <li>Kumho Tyre is committed to sustainable development and has invested heavily in eco-friendly manufacturing practices.</li>
            <li>Kumho Tyre is a global sponsor of sports and cultural events, including the Tottenham Hotspur Football Club and the Bayer 04 Leverkusen Football Club.</li>
          </ul>
          <h2 class="title">Recent achievements</h2>
          <ul>
            <li>In 2022, Kumho Tyre received a silver medal from EcoVadis, a global ESG evaluation agency.</li>
            <li>In 2021, Kumho Tyre was ranked as one of the world's most sustainable companies by Corporate Knights.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
