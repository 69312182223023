<section class="tyres_patterns">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="text">
          <p class="sub_title">All-ways. Go with you.</p>
          <h2 class="title">TYRE PATTERNS</h2>
          <p class="fw_500">
            Kumho Tyre produces a wide range of tyres for cars, trucks, buses, and other vehicles. The company's tyres are known for their quality, performance, and durability. Kumho Tyre has completely focused on the development of “products that customers desire” from the planning stage to the development stage, as can be seen in the case of launching Majesty SOLUS, a premium tyre, and Eco-Wing S, an eco-friendly tyre.
          </p>

          <router-link :to="{ path: '/tyre-range' , hash: '#tyreRange'}" class="btn red_btn">VIEW OUR PATTERNS</router-link>
        </div>
      </div>
    </div>
  </div>
</section>