import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home fl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_NewsRoomDetails = _resolveComponent("NewsRoomDetails", true)!
  const _component_SocialMedia = _resolveComponent("SocialMedia")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_NewsRoomDetails),
    (_ctx.flag == 1)
      ? (_openBlock(), _createBlock(_component_SocialMedia, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}