import { defineComponent } from 'vue';
import config from '@/config/app';
import  "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
export default defineComponent({
  name: 'tyreTab',
  data() {
    return {
      tyreList:config.kumhoTyreRange,
    }
  },
  methods: {
    openInfo(id: any){
      var element :any = document.getElementById('myTabContent');
      var team = element.offsetTop;
      window.scrollTo(0, team);
    },
    getImgUrl(pic : any) {
      var images = require.context('@/assets/images/kumho/tyre-range/')
      return images('./' + pic)
    }
  },
});