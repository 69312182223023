<section class="tyre_range_tabs p-0" id="tyreRange">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation" v-for="(row,index) in tyreList">
      <button
        class="nav-link"
        :class="index== 0 ?'active' :''"
        :id="row.name+'-tab'"
        data-bs-toggle="tab"
        :data-bs-target="'#'+row.name"
        type="button"
        role="tab"
        :aria-controls="row.name"
        aria-selected="true"
        @click="openInfo(row.name)"
      >
        <div class="thumb_wrap">
          <img :src="getImgUrl(row.image)" :alt="row.name" />
          <img
            :src="getImgUrl(row.tyre_image)"
            alt="tyre"
            class="img-fluid tyre"
          />
          <span>{{row.name}}</span>
        </div>
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      v-for="(row,index) in tyreList"
      class="tab-pane fade"
      :class="index== 0 ?'active show' :''"
      :id="row.name"
      role="tabpanel"
      :aria-labelledby="row.name+'-tab'"
    >
      <ul
        class="nav nav-tabs"
        :id="'myTabInner'+index"
        role="tablist"
        v-if="row.typeCount > 0"
      >
        <li
          class="nav-item"
          role="presentation"
          v-for="(type,n) in row.tyreType"
        >
          <button
            class="nav-link"
            :class="n == 0 ?'active' :''"
            :id="type.name+'-tab'"
            data-bs-toggle="tab"
            :data-bs-target="'#'+type.name"
            type="button"
            role="tab"
            :aria-controls="type.name"
            aria-selected="true"
          >
            <span v-html="type.title"></span>
          </button>
        </li>
      </ul>
      <div
        class="tab-content ss"
        :id="'myTabInnerContent'+index"
        v-if="row.typeCount > 0"
      >
        <div
          v-for="(type,n) in row.tyreType"
          class="tab-pane fade"
          :class="n == 0 ?'active show' :''"
          :id="type.name"
          role="tabpanel"
          :aria-labelledby="type.name"
        >
          <section class="wrapper top_content">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-lg-3 d-flex align-items-center">
                  <div class="slider">
                    <div
                      :id="type.name+n"
                      class="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          v-for="(slide,i) in type.slider"
                          :class="i == 0 ?'active' :''"
                          data-interval="1000"
                        >
                          <a :data-fancybox="getImgUrl(slide.image)" :href="getImgUrl(slide.image)">
                            <img
                              :src="getImgUrl(slide.image)"
                              class="d-block w-100"
                              :alt="slide.alt"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="carousel-indicators">
                        <button
                          v-for="(slide,i) in type.slider"
                          type="button"
                          :data-bs-target="'#'+type.name+n"
                          :data-bs-slide-to="i"
                          :class="i == 0 ?'active' :''"
                          aria-current="true"
                          :aria-label="'Slide'+i"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 d-flex align-items-center order-lg-3">
                  <div v-if="type.video">
                    <div class="ratio ratio-16x9">
                      <iframe :src="type.video" allowfullscreen></iframe>
                    </div>
                    <i class="d-block fw-bold lh-1 mt-2">(*Videos for demonstration purpose ONLY, warranties not available for South African Market*)</i>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-5 d-flex align-items-center">
                  <div class="text_wrapper">
                    <div class="text">
                      <p class="sub_title">{{type.subTitle}}</p>
                      <h2 class="title" v-html="type.title"></h2>
                      <p>{{type.description}}</p>

                      <ul class="list_square" v-if="type.features">
                        <template v-for="fs in type.features">
                          <li>
                            <span v-if="fs instanceof Array != true" v-html="fs"></span>
                            <template v-else>
                              <span v-html="fs[0]"></span>
                              <ul>
                                <template v-for="(subFs, index) in fs">
                                  <li v-if="index != 0" v-html="subFs"></li>
                                </template>
                              </ul>
                            </template>
                          </li>
                        </template>
                        <!-- <li>
                          
                        </li> -->
                      </ul>
                    </div>
                    <ul class="btn_group d-none d-lg-flex">
                      <li>
                        <a
                          :href="type.brochure"
                          download
                          target="_blank"
                          class="btn red_btn"
                          >DOWNLOAD PRODUCT BROCHURE</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="product_portfolio">
            <div class="container">
              <p class="sub_title" v-html="type.title"></p>
              <h2 class="title">PRODUCT PORTFOLIO</h2>
              <div class="wrapper">
                <ul class="list_square">
                  <li v-for="pp in type.product_portfolo">{{pp}}</li>
                </ul>
                <ul class="partner_list">
                  <li v-for="(part,num) in type.partner">
                    <img :src="getImgUrl(part.image)" alt="partner" />
                  </li>
                </ul>
                <img
                  class="product_portfolo_image"
                  :src="getImgUrl(type.product_portfolo_image)"
                  alt="partner"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
