<section class="news_details">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-xxl-8">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-xxl-12">
            <h2 class="title border_none">
              {{title}}
              <small class="sub_title d-block" v-if="subTitle">{{subTitle}}</small>
            </h2>
            <div class="big_img mb-3">
              <img
                :src="getImgUrl(bannerImage)"
                :alt="title"
                class="img-fluid w-100"
              />
            </div>
            <small class="img_small_text proxima_med fst-italic mt-2 mb-4 d-block" v-if="bannerImageText">
              {{bannerImageText}}
            </small>
            <div class="article-copy" v-html="description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
