import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08104c60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news_details" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-lg-12 col-xxl-8" }
const _hoisted_5 = { class: "row justify-content-center" }
const _hoisted_6 = { class: "col-lg-12 col-xxl-12" }
const _hoisted_7 = { class: "title border_none" }
const _hoisted_8 = {
  key: 0,
  class: "sub_title d-block"
}
const _hoisted_9 = { class: "big_img mb-3" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = {
  key: 0,
  class: "img_small_text proxima_med fst-italic mt-2 mb-4 d-block"
}
const _hoisted_12 = ["innerHTML"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                (_ctx.subTitle)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.subTitle), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: _ctx.getImgUrl(_ctx.bannerImage),
                  alt: _ctx.title,
                  class: "img-fluid w-100"
                }, null, 8, _hoisted_10)
              ]),
              (_ctx.bannerImageText)
                ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.bannerImageText), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "article-copy",
                innerHTML: _ctx.description
              }, null, 8, _hoisted_12)
            ])
          ])
        ])
      ])
    ])
  ]))
}