<template>
  <div class="home fl">
    <Header />
    <NewsRoomDetails />
    <SocialMedia v-if="flag == 1" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/general/Header/Header.vue";
import NewsRoomDetails from "@/components/general/NewsRoomDetails/NewsRoomDetails.vue";
import SocialMedia from "@/components/general/SocialMedia/SocialMedia.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    NewsRoomDetails,
    SocialMedia,
  },
  data() {
    return {
      flag:this.$route.query.news_room
    }
  },
});
</script>
