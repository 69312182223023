<template>
  <div class="home st">
    <Header />
    <div id="page-not-found" class="col-12 d-flex justify-content-center align-items-center">
      <div class="col-8 col-sm-10 d-flex flex-wrap justify-content-center align-items-center content py-2">
        <h2 class="title text-center h1" data-title="404">404</h2>
        <div class="text-uppercase text-center col-12 h2 proxima_bold my-4 my-lg-5 blurb">We couldn't find what you're looking for</div>
        <router-link :to="{ path: '/' }" class="btn red_btn mb-1">Home</router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/general/Header/Header.vue";

export default defineComponent({
  name: "PageNotFound",
  components: {
    Header,
  },
});
</script>

<style lang="scss" scoped>
#page-not-found {
  height: 98vh;
  height: calc(100vh - 124px);
  min-height: 400px;

  @media (max-width: 575px) {
    min-height: 300px;
  }

  @media (max-width: 479px) {
    height: calc(100vh - 148px);
  }

  @media (min-width: 992px) {
    min-height: 550px;
  }

  @media (min-width: 1200px) {
    min-height: 600px;
    height: calc(100vh - 138px);
  }

  .content {
    align-content: center;

    .title {
      font-size: 3rem;
      letter-spacing: 10px;
      margin-top: 0;
      margin-bottom: initial;
      

      &::before {
        font-size: 1.35em;
        bottom:17px;
        left: 3px;
        word-break: normal;
      }

      &::after {
        bottom: 0;
        width: 100%;
      }

      @media (min-width: 360px) {
        font-size: 5rem;
        margin-top: 0;

        &::before {
          font-size: 1.4em;
          bottom:45px;
          left: 3px;
        }

        &::after {
          bottom: 0;
        }
      }

      @media (min-width: 576px) {
        font-size: 8rem;
        margin-top: 70px;
        margin-bottom: 80px;

        &::before {
          font-size: 1.4em;
          bottom: 15px;
          left: 6px;
        }

        &::after {
          bottom: -70px;
        }
      }

      @media (min-width: 992px) {
        font-size: 14rem;
        letter-spacing: 10px;
        margin-top: 90px;
        margin-bottom: 100px;

        &::before {
          font-size: 1.4em;
          bottom: 31px;
          left: 10px;
        }

        &::after {
          bottom: -90px;
        }
      }
    }
  }

  .blurb {
    @media (max-width: 991px) {
      font-size: calc(1rem + 0.6vw);
    }

    @media (max-width: 359px) {
      font-size: calc(0.9rem + 0.2vw);
    }

    @media (min-width: 992px) {
      font-size: 1.8rem !important;
    }
  }

  .btn {
    min-width: 160px;
    font-size: 14px;
    padding: 8px 25px;
    min-height: 39px;

    &:hover {
      color: white;
    }
  }
}
</style>
