<section class="social_media_sec overlay_bg" id="kumhoContactUs">
  <div class="container">
    <h2 class="title text-center white border_none">
      FOLLOW US ON SOCIAL MEDIA
    </h2>
    <ul class="socail_list">
      <li>
        <a href="https://www.facebook.com/profile.php?id=61553083419279" target="_blank">
          <img
            src="@/assets/images/icons/facebook.webp"
            alt="Facebook"
          />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/kumho_tyressa/" target="_blank">
          <img
            src="@/assets/images/icons/instagram.webp"
            alt="Instagram"
          />
        </a>
      </li>
    </ul>
  </div>
</section>
