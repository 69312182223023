<section class="the_story p-0 black2_bg white" id="kumhoAboutUs">
  <div class="container-fluid p-0">
    <div class="row g-0 flex-md-row-reverse">
      <div class="col-lg-6">
        <div class="img">
          <img
            src="@/assets/images/kumho/kumho-car.webp"
            alt="The Stroy"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-end">
        <div class="text">
          <p class="sub_title">Kumho Tyre  is a global tyre company that was founded in 1960</p>
          <h2 class="title title_2 white">THE STORY</h2>
          <div
            id="carouselExampleCaptions"
            class="carousel slide slider_wrapper"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p>
                  Kumho Tyre's world-renowned quality is a result of a strong commitment to advanced design technology and drive to innovate. Excellent products that deliver optimum performance, quality, and most importantly, safety, are the Kumho Tyre promise. Having grown to the 7th largest company in Korea, Kumho Tyres runs three manufacturing facilities in South Korea. There are a further three plants in China as well as one plant in Vietnam.
                </p>
                <p>
                  Kumho Tyres exports tyres worldwide and has a global network of sales organisations including Stamford Tyres Africa (Pty) Ltd. Kumho Tyres has three centres for research and development, with the largest in Gwangju, South Korea. The other two centres are in Akron, Ohio and Birmingham, West Midlands. These service the US and European tyre markets respectively.
                </p>
                <p>
                  Kumho Tyres has a global presence across 180 countries and produces more than 68 million tyres every year. Kumho defines itself through innovation and quality. Alongside supporting a whole host of Motorsport events, Kumho has also developed a tyre for F1.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
