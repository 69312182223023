import { defineComponent } from 'vue';
import EventBus from '@/EventBus';

export default defineComponent({
  name: 'social-media',
  // mounted (){
  //   var element : any
  //   EventBus.on('my-kumho-home', (evt : any) => {
  //     if (evt.eventContent == 'kumhoContactUs') {
  //       element = document.getElementById("kumhoContactUs");
  //       var team = element.offsetTop;
  //       window.scrollTo(0, team);
  //     }
  //   })
  // }
});