<section class="st_contact p-0" id ="contactUsHome">
  <div class="container wrapper">
    <div class="row">
      <div class="col-lg-7 white_bg">
        <div class="contact_us_box">
          <h2 class="title" data-title="contact us">contact us</h2>
          <h3 class="sub_title">
            Have a question? <br />
            Send us a message and we <br /> will be in touch.  
          </h3>
          <div class="contact_details_wrap">
            <p><b>CONTACT DETAILS</b></p>
            <ul>
              <li class="icon call">
                <a href="tel:0860522322" target="_blank">0860 522 322</a>
              </li>
              <li class="icon email">
                <a href="mailto:telesales@stamford.co.za"
                  >telesales@stamford.co.za</a
                >
              </li>
            </ul>
          </div>
          <div class="add_details_wrap">
            <ul>
              <li class="icon add">
                <b class="d-block text-upppercase">CAPE TOWN </b> 10 Ferrule
                Avenue, Montague Gardens, Cape Town, 7441
              </li>
              <li class="icon add">
                <b class="d-block text-upppercase">JOHANNESBURG</b> 4 Brine
                Avenue, Chloorkop, Extension 1, 1624, Johannesburg
              </li>
              <li class="icon add">
                <b class="d-block text-upppercase">DURBAN</b> 7 Stanhope Pl,
                Briardene, Durban, 4051
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-5 grey_light_bg">
        <div class="form_wrap">
          <h2 class="title" data-title="ENQUIRE">ENQUIRE</h2>
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" @invalid-submit="formSubmission=false;sendError=false;">
            <div class="form-group">
              <label for="" class="form-label">Name:</label>
              <Field :disabled="loading" type="text" name="name"  v-model="form.name" :class="{ 'is-invalid': errors.name }" class="form-control" />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>
            <div class="form-group">
              <label for="" class="form-label">Phone:</label>
              <Field :disabled="loading" type="text" name="phone" v-model="form.phone" :class="{ 'is-invalid': errors.phone }" class="form-control" />
              <div class="invalid-feedback">{{ errors.phone }}</div>
            </div>
            <div class="form-group">
              <label for="" class="form-label">email:</label>
              <Field :disabled="loading" type="email" name="email" v-model="form.email" :class="{ 'is-invalid': errors.email }"  class="form-control" />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="form-group">
              <label for="" class="form-label">Subject:</label>
              <Field :disabled="loading" as="select" name="subject" v-model="form.subject" :class="{ 'is-invalid': errors.subject }" id="disabledSelect" class="form-control form-select">
                <option v-if="!form.subject" value="">Choose...</option>
                <option :value="dealerFormKey">Become a dealer</option>
                <option :value="enquiryFormKey">Send an enquiry</option>
              </Field>
              <div class="invalid-feedback">{{ errors.subject }}</div>
            </div>
            <div class="form-group">
              <label for="" class="form-label">Message:</label>
              <Field :disabled="loading" as="textarea" name="message" v-model="form.message" :class="{ 'is-invalid': errors.message }" class="form-control"></Field>
              <div class="invalid-feedback">{{ errors.message }}</div>
            </div>
            <button :disabled="loading" type="submit" class="btn red_btn" style="height: 60px;">
              <span v-if="!loading">SUBMIT ENQUIRY</span>
              <span v-else>
                <Spinner :visible="loading" />
              </span>
            </button>
            <div v-if="formSubmission" class="col-12 mt-3">
              <b>Email sent successfully! Thank you.</b>
            </div>
            <div v-else-if="sendError" class="col-12 mt-3">
              <b>Error sending form. Please <a href="mailto:telesales@stamford.co.za" class="text-decoration-none">contact us via email</a>.</b>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</section>
