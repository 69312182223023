import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home fl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HomeBanner = _resolveComponent("HomeBanner")!
  const _component_HomeStory = _resolveComponent("HomeStory")!
  const _component_TyrePatterns = _resolveComponent("TyrePatterns")!
  const _component_ThePulse = _resolveComponent("ThePulse")!
  const _component_KumhoWarranty = _resolveComponent("KumhoWarranty")!
  const _component_SocialMedia = _resolveComponent("SocialMedia")!
  const _component_HomeContact = _resolveComponent("HomeContact")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_HomeBanner),
    _createVNode(_component_HomeStory),
    _createVNode(_component_TyrePatterns),
    _createVNode(_component_ThePulse),
    _createVNode(_component_KumhoWarranty),
    _createVNode(_component_SocialMedia),
    _createVNode(_component_HomeContact)
  ]))
}