import { defineComponent } from 'vue';
import config from '@/config/app';
export default defineComponent({
  name: 'news-page',
  data() {
    return {
      roomList:config.newsRoomDetails,
    }
  },
  methods: {
    roomDetails(data : any){
      this.$router.push({ path: 'news-room-details', query: { news_room: data.id }})
    },
    getImgUrl(pic : any) {
      var images = require.context('@/assets/images/kumho/news-page-content/')
      return images('./' + pic)
    }
  },
});