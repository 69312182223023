<section class="the_pulse">
  <div class="container">
    <div class="row flex-lg-row-reverse">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="video">
          <iframe
            src="https://www.youtube.com/embed/N85HmUSxu0c"
            title="Kumho Tyre"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <div class="text white">
          <p class="sub_title">All-ways. Go with you.</p>
          <h2 class="title title_2 white">
            Lead the future,<br />
            realize innovation,<br />
            and pursue changes<br />
            with an open mind.
          </h2>
        </div>
      </div>
    </div>
  </div>
</section>
